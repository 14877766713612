import axios from 'axios'
import env from '../env'

const baseURL = () =>
  axios.create({
    baseURL: env.baseUrl,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

export const customerListCards = (search?: string) => {
  return baseURL().get(`customers/cards${search}`)
}

export const firstCards = (props: { number: string }) => {
  return baseURL().get(`customers/cards/${props.number}`)
}

export const transactionHistories = (props: { cardNumber: string }) => {
  return baseURL().get(
    `customers/card-transaction-histories?cardNumber=${props.cardNumber}&operationTypes=1,2,4&page=1&countPerPage=999`
  )
}

export const allTransactionHistories = (props: { search: string }) => {
  if (props.search) {
    return baseURL().get(
      `customers/card-transaction-histories${props.search}&operationTypes=2,4&page=1&countPerPage=999`
    )
  }
  return baseURL().get(
    `customers/card-transaction-histories?operationTypes=2,4&page=1&countPerPage=999`
  )
}

export const updatePinCodeCards = (props: {
  number: string
  pinCode: string
}) => {
  return baseURL().patch(`customers/cards/${props.number}`, {
    pinCode: props.pinCode,
  })
}
export const updateNameCards = (props: { number: string; title: string }) => {
  return baseURL().patch(`customers/cards/${props.number}`, {
    title: props.title,
  })
}

export const updateStatusCard = (props: { number: string; status: string }) => {
  return baseURL().patch(`customers/cards/${props.number}`, {
    status: props.status,
  })
}
