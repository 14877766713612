import { Box, Button, FormControl, Modal } from '@mui/material'
import React from 'react'
import { style } from '../../styles/style'
import QRCode from 'react-qr-code'

type Props = {
  handleClose: () => void
  open: boolean
  number: string
}

export const ModalQrCode = (props: Props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 'calc(100% - 100px)', maxWidth: 400 }}>
        <h2
          className={'medium-text'}
          style={{ fontSize: 22, fontWeight: 'bold' }}
          id="medium-text"
        ></h2>
        <div
          className={'medium-text'}
          style={{ textAlign: 'center', marginBottom: 20 }}
        >
          <QRCode
            style={{
              height: 'auto',
              textAlign: 'center',
              maxWidth: '100%',
              width: '90%',
            }}
            value={props.number}
            viewBox={`0 0 256 256`}
          />
        </div>
        <div className={'station-form__block-buttons'}>
          <Button
            variant="contained"
            className={'btn-default btn-blue btn-icon-cancel'}
            onClick={props.handleClose}
          >
            Закрыть
          </Button>
        </div>
      </Box>
    </Modal>
  )
}
