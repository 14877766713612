import { Button } from '@mui/material'
import { format } from 'date-fns'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { GoBack } from '../../components/go-back'
import { TitleComponent } from '../../components/title'
import { Transactions } from '../../formatters/type-transaction'
import { getLiter } from '../../helpers/getLiter'
import { getUserNameLocalStorage } from '../../helpers/getUserNameLocalStorage'
import { getPrintClientTransactions } from '../../print-reports/print-client-transactions'
import {
  allTransactionHistories,
  transactionHistories,
} from '../../request/cards'
import Filter from './filter'
import './style.scss'

export const ReportProtocol = () => {
  const [transactions, setTransactions] = React.useState<any>(null)

  const location = useLocation()

  useEffect(() => {
    allTransactionHistories({ search: location.search }).then(data =>
      setTransactions(data.data)
    )
  }, [location.search])

  return (
    <div className={'container report-protocol'}>
      <GoBack url={'/'} />
      <TitleComponent title={'Протокол транзакций'} />
      <div className={'report-protocol__filter'}>
        <Filter />
      </div>
      {/* <div style={{ width: 180, marginTop: 32 }}>
        <Button
          variant={'contained'}
          sx={{ width: '170px' }}
          className={'btn-default btn-green bnt-icon-ok'}
          onClick={() => {
            const params = new URLSearchParams(location.search)
            let filter = {}
            params.forEach((value, key) => {
              if (key === 'dateTo' || key === 'dateFrom') {
                filter = { ...filter, [key]: new Date(value) }
              } else {
                filter = { ...filter, [key]: value }
              }
            })
            getPrintClientTransactions({
              filters: filter,
              record: transactions,
              customer: getUserNameLocalStorage(),
            })
          }}
        >
          Печать протокола
        </Button>
      </div> */}
      <div>
        <TitleComponent title={'Список транзакций'} style={{ fontSize: 22 }} />
        <div className={'transaction-table'}>
          <div className={'transaction-table__head'}>
            <div>Номер</div>
            <div>Тип</div>
            <div>Вид топлива</div>
            <div>Количество (л.)</div>
            <div>АЗС</div>
            <div>Дата</div>
          </div>
          {transactions && transactions.items ? (
            transactions.items?.map((trans: any) => {
              return (
                <div key={trans.id} className={'transaction-table__body'}>
                  <div>{trans?.card?.number || '-'}</div>
                  <div>{Transactions(trans.operationType)}</div>
                  <div>{trans?.fuel?.title || '-'}</div>
                  <div>{getLiter(trans.amount, trans.literPrice)} л.</div>
                  <div>{trans?.petrolStation?.title || '-'}</div>
                  <div>
                    {format(new Date(trans.createdAt), 'dd.MM.yyyy в HH:mm') ||
                      '-'}
                  </div>
                </div>
              )
            })
          ) : (
            <div>Список пуст</div>
          )}
        </div>
      </div>
      <div className={'transactions-mobile'}>
        {transactions?.items ? (
          transactions.items?.map((trans: any) => {
            const countLiter = getLiter(trans.amount, trans.literPrice) as any

            return (
              <div key={trans.id} className={'transactions-mobile__block'}>
                <div className={'transactions-mobile__block__head'}>
                  <div>
                    {trans?.petrolStation?.title || '-'}
                    <p>
                      {trans?.petrolStation?.address ||
                        'Барнаул ул. Трактовая 64'}
                    </p>
                  </div>
                  <div>
                    {format(new Date(trans.createdAt), 'dd.MM.yyyy в HH:mm') ||
                      '-'}
                  </div>
                </div>
                <div
                  style={{
                    fontWeight: '600',
                    marginTop: 5,
                    color: Math.sign(countLiter) === -1 ? '#D22323' : '#00693E',
                  }}
                >
                  {countLiter && Math.sign(countLiter) === 1
                    ? '+' + countLiter
                    : countLiter}{' '}
                  л.
                </div>
                <div className={'transactions-mobile__block__body'}>
                  <div>{trans?.fuel?.title || '-'} /</div>
                  <div
                    style={{
                      color:
                        Math.sign(countLiter) === -1 ? '#D22323' : '#00693E',
                    }}
                  >
                    {Transactions(trans.operationType)}
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div>Список пуст</div>
        )}
      </div>
    </div>
  )
}
