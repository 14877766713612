import React from 'react'

export const TitleComponent = ({
  title,
  style,
}: {
  title: string
  style?: any
}) => (
  <div className={'large-text title-component'} style={{ ...style }}>
    {title}
  </div>
)
