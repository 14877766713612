import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, FormControl, Modal } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { TextInputRa } from '../../components/inputs/text-field'
import { updateClients } from '../../request/clients'
import { style } from '../../styles/style'
import { schemaChangePassword } from './schema'

type Props = {
  handleClose: () => void
  open: boolean
}

export const ModalChangePassword = (props: Props) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaChangePassword),
    defaultValues: { password: null },
  })

  const [success, setSuccess] = React.useState(false)

  const onSubmit = (values: any) => {
    updateClients({ ...values })
      .then(() => {
        setSuccess(true)
      })
      .catch(error =>
        setError('password', { message: error.response?.data?.description })
      )
  }
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, paddingLeft: '20px', paddingRight: '20px' }}>
        <h2
          className={'medium-text'}
          style={{ fontSize: 22, fontWeight: 'bold' }}
          id="medium-text"
        >
          Смена пароля
        </h2>
        {success ? (
          <div className={'change-password-form__success-form'}>
            <div className={'medium-text'} style={{ minWidth: 300 }}>
              Пароль успешно изменен!
            </div>
            <Button
              style={{ marginTop: 20, width: 150 }}
              variant="contained"
              className={'btn-default btn-gray btn-icon-cancel'}
              onClick={props.handleClose}
            >
              Закрыть
            </Button>
          </div>
        ) : (
          <FormControl fullWidth className={'mui-input'}>
            <TextInputRa
              name={'password'}
              label={'Новый пароль'}
              control={control}
              errors={errors}
            />
            <div className={'change-password-form__block-buttons'}>
              <Button
                variant="contained"
                className={'btn-default btn-blue bnt-icon-ok'}
                onClick={handleSubmit(onSubmit)}
              >
                Изменить
              </Button>
              <Button
                variant="contained"
                className={'btn-default btn-gray btn-icon-cancel'}
                onClick={props.handleClose}
              >
                Отмена
              </Button>
            </div>
          </FormControl>
        )}
      </Box>
    </Modal>
  )
}
