export type StatusCard = 'active' | 'blocked_admin' | 'deleted' | 'blocked_self'

export function getStatus(status: StatusCard) {
  switch (status) {
    case 'active':
      return 'Активная'
    case 'blocked_admin':
      return 'Заблокирована'
    case 'blocked_self':
      return 'Заморожена'
  }
}
