import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AuthContext } from '../../context/authProvider'
import { requestAuth, userMe } from '../../request'
import { TextInputRa } from '../../components/inputs/text-field'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schemaAuth } from './schema'
import { FormControl } from '@mui/material'
import { TextInputPhoneRa } from '../../components/inputs/text-field-phone'
import { phoneClear } from '../../formatters/clear-phone'

const theme = createTheme()

export const SignIn = () => {
  const { setIsAuth }: any = React.useContext(AuthContext)

  const onSubmit = (values: any) => {
    requestAuth({
      username: phoneClear(values.username),
      password: values.password,
    })
      .then(data => {
        localStorage.setItem('token', data.data.token)
        userMe().then(data => {
          localStorage.setItem('user', JSON.stringify(data.data))
          setIsAuth(true)
        })
      })
      .catch(e => {
        setError('password', {
          message: 'Ошибка авторизации. Проверьте логин и пароль.',
        })
      })
  }

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaAuth),
    defaultValues: {
      username: null,
      password: null,
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            style={{ width: 300, marginBottom: 20 }}
            src={'/assets/logo.png'}
          />
          <Typography component="h1" variant="h5">
            Личный кабинет
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{
              mt: 1,
              boxShadow: '0px 1px 16px rgb(0 46 82 / 10%)',
              padding: 6,
              borderRadius: 4,
              maxWidth: '400px',
              width: '100%',
            }}
          >
            <FormControl fullWidth className={'mui-input'}>
              <TextInputPhoneRa
                control={control}
                errors={errors}
                id="phone"
                label="Логин"
                name="username"
                autoComplete="username"
                autoFocus
              />
              <TextInputRa
                control={control}
                errors={errors}
                name="password"
                label="Пароль"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <div
                className={'station-form__block-buttons'}
                style={{ display: 'flex' }}
              >
                <Button
                  variant="contained"
                  className={'btn-default btn-blue bnt-icon-ok'}
                  onClick={handleSubmit(onSubmit)}
                >
                  Войти
                </Button>
              </div>
            </FormControl>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
