import { Button } from '@mui/material'
import { format } from 'date-fns'
import React from 'react'
import { GoBack } from '../../components/go-back'
import { TitleComponent } from '../../components/title'
import { getUserName } from '../../helpers/getUserName'
import { ModalChangePassword } from './modal-change-password'
import './style.scss'
import { Tabs } from './tab'

const ProfilePage = () => {
  const [openChange, setOpenChange] = React.useState(false)
  const data = JSON.parse(localStorage.getItem('user') as any)

  return (
    <React.Fragment>
      <ModalChangePassword
        open={openChange}
        handleClose={() => setOpenChange(false)}
      />
      <div className={'profile container'}>
        <GoBack />
        <TitleComponent title={'Профиль'} />
        <div>
          <Tabs title={'ФИО'} value={getUserName(data)} />
          <Tabs title={'Телефон'} value={data.phone} />
          <Tabs
            title={'Наименование компании'}
            value={data.companyTitle || '-'}
          />
          <Tabs title={'ИНН'} value={data.companyInn || '-'} />
          <Tabs
            title={'Дата активации'}
            value={format(new Date(data.createdAt), 'dd.MM.yyyy')}
          />
        </div>
        <div
          className={'change-password-button '}
          onClick={() => setOpenChange(true)}
        >
          <div
            style={{
              padding: 5,
              fontWeight: '600',
              color: 'var(--color-text)',
            }}
          >
            Изменить пароль
          </div>
          <div style={{ marginLeft: 'auto', display: 'flex' }}>
            <img src={'/assets/icons/next.svg'} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default ProfilePage
