export const Transactions = (type: number) => {
  switch (type) {
    case 1:
      return 'Пополнение'
    case 2:
      return 'Списание'
    case 3:
      return 'Коррекция'
    case 4:
      return 'Возврат'
    default:
      return '-'
  }
}

export function isReportTransaction(type: number) {
  if (type === 2 || type === 4) {
    return true
  } else {
    return false
  }
}
