import React from 'react'
import { useNavigate } from 'react-router'
import './style.scss'

export const GoBack = ({ url = null }: { url?: null | string }) => {
  const navigate = useNavigate()

  return (
    <div
      onClick={() => (url ? navigate(url) : navigate(-1))}
      className={'back'}
    >
      <img src={'/assets/back.svg'} />
      Назад
    </div>
  )
}
