import React from 'react'
import { IMaskInput } from 'react-imask'

export const TextMaskCustom = React.forwardRef(function TextMaskCustom(
  props: any,
  ref
) {
  const { onChange, ...other }: any = props
  return (
    <IMaskInput
      {...other}
      mask="+{7}(000) 000 0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={value => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
})
