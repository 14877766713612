import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, FormControl, Modal } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { TextInputRa } from '../../components/inputs/text-field'
import { updateNameCards } from '../../request/cards'
import { style } from '../../styles/style'
import { schemaUpdateNameCard } from './schema'

type Props = {
  handleClose: () => void
  open: boolean
  uuid: number
  title: string
}

export const ModalUpdateNameCard = (props: Props) => {
  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaUpdateNameCard),
    defaultValues: { title: props.title || '' },
  })

  const [success, setSuccess] = React.useState(false)

  const onSubmit = (values: any) => {
    updateNameCards({ number: props.uuid, ...values })
      .then(() => {
        setSuccess(true)
        setTimeout(() => {
          props.handleClose()
          setSuccess(false)
          reset()
        }, 1000)
      })
      .catch(error =>
        setError('title', { message: error.response?.data?.description })
      )
  }
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          ...style,
          paddingLeft: '20px',
          paddingRight: '20px',
          width: 'max-content',
        }}
      >
        <h2
          className={'medium-text'}
          style={{ fontSize: 22, fontWeight: 'bold' }}
          id="medium-text"
        >
          Обновить имя карты
        </h2>
        {success ? (
          <div className={'medium-text'}>Имя карты успешно обновленно!</div>
        ) : (
          <FormControl fullWidth className={'mui-input'}>
            <TextInputRa
              name={'title'}
              label={'Новое имя карты'}
              control={control}
              errors={errors}
              autoFocus={true}
            />
            <div className={'block-buttons'}>
              <Button
                variant="contained"
                className={'btn-default btn-blue bnt-icon-ok'}
                onClick={handleSubmit(onSubmit)}
              >
                Изменить
              </Button>
              <Button
                variant="contained"
                className={'btn-default btn-gray btn-icon-cancel'}
                onClick={props.handleClose}
              >
                Отмена
              </Button>
            </div>
          </FormControl>
        )}
      </Box>
    </Modal>
  )
}
