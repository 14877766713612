import axios from 'axios'
import env from '../env'
import { Client } from '../types/clients'

const baseURL = () =>
  axios.create({
    baseURL: env.baseUrl,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

export const updateClients = (props: Client) => {
  return baseURL().patch(`current-customers`, { ...props })
}
