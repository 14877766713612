import React from 'react'
import './App.scss'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import { SignIn } from './pages/auth'
import { AuthContext } from './context/authProvider'
import Header from './pages/header'
import { userMe } from './request'
import HomePage from './pages/home'
import CardDetail from './pages/cards'
import ProfilePage from './pages/profile'
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack'
import { ReportProtocol } from './pages/reports'

const App = () => {
  const [isAuth, setIsAuth] = React.useState<Boolean>(false)

  React.useEffect(() => {
    userMe().then(() => {
      setIsAuth(true)
    })
  }, [])

  return (
    <AuthContext.Provider value={{ isAuth, setIsAuth }}>
      <SnackbarProvider maxSnack={3}>
        <BrowserRouter>
          {isAuth ? (
            <>
              {isAuth && <Header />}
              <Routes>
                <Route path={'/cards/:id'} element={<CardDetail />} />
                <Route path={'/profile'} element={<ProfilePage />} />
                <Route path={'/report'} element={<ReportProtocol />} />
                <Route path="/*" element={<HomePage />} />
              </Routes>
            </>
          ) : (
            <Routes>
              <Route path="*" element={<SignIn />} />
            </Routes>
          )}
        </BrowserRouter>
      </SnackbarProvider>
    </AuthContext.Provider>
  )
}

export default App
