import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

import AccountBoxIcon from '@mui/icons-material/AccountBox'
import SummarizeIcon from '@mui/icons-material/Summarize'

const tabs = [
  { path: '/profile', title: 'Профиль', Icon: AccountBoxIcon },
  { path: '/report', title: 'Отчеты', Icon: SummarizeIcon },
]

export const Menu = () => {
  return (
    <div className={'lk-menu'}>
      {tabs.map(tab => {
        return (
          <Link className={'lk-menu__tab'} key={tab.path} to={tab.path}>
            <tab.Icon className={'lk-menu__tab__link'} sx={{}} />
            <span>{tab.title}</span>
          </Link>
        )
      })}
    </div>
  )
}
