import React, { useEffect } from 'react'
import { Button, FormControl } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { TextInputRa } from '../../components/inputs/text-field'
import './style.scss'
import { TextInputDateRa } from '../../components/inputs/date-field'
import { endOfMonth, startOfMonth } from 'date-fns'

type Props = {
  defaultValues?: { number?: string; title?: string }
}

export const Filter = (props: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  let params = new URLSearchParams(location.search)

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cardNumber: '' as any,
      dateTo: null,
      dateFrom: null,
    },
  })

  useEffect(() => {
    params.forEach((value, key) => {
      if (key === 'dateTo' || key === 'dateFrom') {
        setValue(key as any, new Date(value))
      } else {
        setValue(key as any, value)
      }
    })
  }, [])

  const resetFilter = () => {
    reset({
      cardNumber: '' as any,
      dateTo: null as any,
      dateFrom: null as any,
    })
    navigate(`/report`)
  }

  const onSubmit = (values: any) => {
    params.delete('dateTo')
    params.delete('dateFrom')
    params.delete('cardNumber')
    Object.entries(values).map(field => {
      if (field[0] === 'dateTo' || field[0] === 'dateFrom') {
        if (field[1]) {
          params.append(field[0], String(new Date(field[1] as any).toJSON()))
        }
      } else if (field[1]) {
        params.append(field[0], String(field[1]))
      }
    })

    navigate(`/report?${params.toString()}`)
  }

  return (
    <FormControl fullWidth className={'mui-input'}>
      <div className={'na-filter__fields'}>
        <div className={'na-filter__fields__field'}>
          <TextInputRa
            name={'cardNumber'}
            label={'Номер карты'}
            control={control}
            errors={errors}
            placeholder={'Введите номер карты'}
          />
        </div>
        <div className={'na-filter__fields__field'}>
          <TextInputDateRa
            name={'dateFrom'}
            control={control}
            label={'Дата от'}
            errors={errors}
          />
        </div>
        <div className={'na-filter__fields__field'}>
          <TextInputDateRa
            name={'dateTo'}
            control={control}
            label={'Дата до'}
            errors={errors}
          />
        </div>
      </div>

      <div className={'na-filter__buttons'}>
        <Button
          variant="contained"
          className={'btn-default btn-blue bnt-icon-ok'}
          onClick={handleSubmit(onSubmit)}
        >
          Применить
        </Button>
        <Button
          variant="contained"
          className={'btn-default btn-gray btn-icon-cancel'}
          onClick={resetFilter}
        >
          Очистить
        </Button>
      </div>
    </FormControl>
  )
}

export default Filter
