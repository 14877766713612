import * as yup from 'yup'

export const schemaChangePassword = yup
  .object({
    password: yup
      .string()
      .min(6, 'Минимальная длинна 6 символов')
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
  })
  .required()
