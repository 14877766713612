import axios from 'axios'
import env from '../env'

const baseURLNotToken = () =>
  axios.create({
    baseURL: env.baseUrl,
    headers: {},
  })

const baseURL = () =>
  axios.create({
    baseURL: env.baseUrl,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

export const requestAuth = ({
  username,
  password,
}: {
  username: string
  password: string
}) => {
  return baseURLNotToken().post(`customer-tokens`, {
    phone: username,
    password: password,
  })
}

export const userMe = () => {
  return baseURL().get(`current-customers`)
}
