import * as yup from 'yup'

export const schemaAuth = yup
  .object({
    password: yup
      .string()
      .trim()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
    username: yup
      .string()
      .trim()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
  })
  .required()
