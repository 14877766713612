import * as yup from 'yup'

export const schemaChangePinCode = yup
  .object({
    pinCode: yup
      .string()
      .length(4, 'Длинна пинкода должна быть 4 символа')
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
  })
  .required()
