import React from 'react'

export const Tabs = ({ title, value }: { title: string; value: string }) => {
  return (
    <div className={'card-detail__params'}>
      <div className={'card-detail__params__title'}>{title} : </div>
      <div className={'card-detail__params__value'}> {value}</div>
    </div>
  )
}
