import * as yup from 'yup'

export const schemaUpdateNameCard = yup
  .object({
    title: yup
      .string()
      .max(100, 'Длинна текста не более 100 символов')
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
  })
  .required()
