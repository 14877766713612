import React, { useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import { TitleComponent } from '../../components/title'
import { format } from '../../formatters/format'
import { customerListCards } from '../../request/cards'
import './style.scss'
import classnames from 'classnames'
import EditIcon from '@mui/icons-material/Edit'
import { ModalUpdateNameCard } from './modal-update-name'
import { getLiterAmount } from '../../formatters/liter-amount'
import Filter from './filter'
import { Menu } from './menu/Menu'

const HomePage = () => {
  const location = useLocation()

  const [data, setData] = React.useState([])
  const navigate = useNavigate()
  const [isUpdateName, setIsUpdateName] = React.useState({
    isUpdate: false,
    id: null,
    title: '',
  })

  useEffect(() => {
    getCards()
  }, [location.search])

  function getLiter(amount: string, price: string) {
    if (Number(amount) && Number(price)) {
      return getLiterAmount(Number(amount) / Number(price)) || '-'
    } else return '0'
  }

  function getCards() {
    customerListCards(location.search).then(data => setData(data.data.items))
  }

  function handleOpen(uuid: string, title: string) {
    setIsUpdateName({ isUpdate: true, id: uuid as any, title: title })
  }
  function handleClose() {
    setIsUpdateName({ isUpdate: false, id: null, title: '' })
    getCards()
  }

  return (
    <div className={' container'}>
      <Menu />
      {isUpdateName.isUpdate && (
        <ModalUpdateNameCard
          handleClose={handleClose}
          open={isUpdateName.isUpdate}
          uuid={isUpdateName.id as any}
          title={isUpdateName.title}
        />
      )}
      <TitleComponent title={'Список карт'} />
      <Filter />

      <div className="home">
        {data &&
          data.map((item: any) => (
            <div
              key={item.id}
              className={classnames('custom-card', {
                'custom-card__blocked': item.status !== 'active',
              })}
            >
              <div onClick={() => navigate(`/cards/${item.number}`)}>
                <div className={'custom-card__status-block'} />
                <div className={'custom-card__logo'}>
                  <img src={'/assets/logo.png'} />
                </div>
                <div className={'custom-card__number'}>
                  {format(item.number)}
                </div>

                <div className={'custom-card__block-balance'}>
                  <div className={'custom-card__block-balance__title'}>
                    Баланс
                  </div>
                  <div className={'custom-card__block-balance__fuels'}>
                    {item && item.balances
                      ? item?.balances?.map((balance: any) => {
                          return (
                            <p
                              key={balance?.id}
                              style={{ margin: 0, marginBottom: 4 }}
                              className={'custom-card__block-balance__value'}
                            >
                              {balance?.fuel?.title || '-'} ={' '}
                              {getLiter(balance.amount, balance.purchasePrice)}{' '}
                              л
                            </p>
                          )
                        })
                      : '-'}
                  </div>
                </div>
              </div>
              <div className={'custom-card__custom-name'}>
                <div className={'custom-card__block-balance__title'}>
                  Наименование карты
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 8,
                  }}
                >
                  <span style={{ color: 'var(--color-text)' }}>
                    {item.title || 'Имя не задано'}
                  </span>
                  <EditIcon
                    style={{ marginLeft: '16px' }}
                    sx={{ height: '20px' }}
                    onClick={() => {
                      handleOpen(item.uuid, item.title)
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default HomePage
