import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LogoutIcon from '@mui/icons-material/Logout'
import './style.scss'
import { AuthContext } from '../../context/authProvider'
import { permission } from '../../permission'
import { userMe } from '../../request'

const ElemMenu = (props: {
  id: number
  name: string
  link: string
  permission: null | string
  icon: any
}) => {
  if (props.permission) {
    if (permission(props.permission)) {
      return (
        <Link
          className={'header__menu__link medium-text'}
          to={props.link}
          title={props.name}
        >
          <props.icon
            fontSize={'small'}
            sx={{ color: 'var(--color-text)', paddingRight: '5px' }}
          />
          {props.name}
        </Link>
      )
    }
    return <span />
  }
  return (
    <Link
      className={'header__menu__link medium-text'}
      to={props.link}
      title={props.name}
    >
      <props.icon
        fontSize={'small'}
        sx={{ color: 'var(--color-text)', paddingRight: '5px' }}
      />
      {props.name}
    </Link>
  )
}

const Header = () => {
  const [data, setData] = React.useState(
    JSON.parse(localStorage.getItem('user') as any)
  )
  const navigate = useNavigate()
  const { setIsAuth }: any = React.useContext(AuthContext)

  return (
    <header id={'header'} className={'header'}>
      <div className={'header__panel'}>
        <div className={'header__panel__elem'}>
          <span
            onClick={() => navigate('/profile')}
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            <img src={'/assets/profile.svg'} />
            <span className={' medium-text'}>
              {data.lastName} {data.firstName.split('')[0]}.
            </span>
          </span>
          <span
            onClick={() => {
              navigate('/')
              setIsAuth(false)
              localStorage.removeItem('token')
              localStorage.removeItem('user')
            }}
          >
            <LogoutIcon className={'header__panel__elem__exit'} />
          </span>
        </div>
      </div>
    </header>
  )
}

export default Header
