import { Button, CircularProgress, Menu, MenuItem } from '@mui/material'
import { format } from 'date-fns'
import React from 'react'
import { useParams } from 'react-router'
import { GoBack } from '../../components/go-back'
import { Preloader } from '../../components/preloader'
import { TitleComponent } from '../../components/title'
import { Transactions } from '../../formatters/type-transaction'
import { getStatus } from '../../hooks/useStatus'
import {
  firstCards,
  transactionHistories,
  updateStatusCard,
} from '../../request/cards'
import { ModalQrCode } from './modal-qr-code'
import './style.scss'
import CircleIcon from '@mui/icons-material/Circle'
import { ModalChangePinCode } from './modal-change-pincode'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import GppGoodIcon from '@mui/icons-material/GppGood'
import { getLiter } from '../../helpers/getLiter'

const Tabs = ({ title, value }: { title: string; value: string }) => {
  return (
    <div className={'card-detail__params'}>
      <div className={'card-detail__params__title'}>{title} : </div>
      <div className={'card-detail__params__value'}> {value}</div>
    </div>
  )
}

const Card = ({ value, title }: { value?: string; title: string }) => (
  <div
    className={'home__widgets__block'}
    style={{ backgroundImage: 'url(/assets/background/circle.svg)' }}
  >
    <div className={'home__widgets__block__value'}>{value || '-'}</div>
    <div className={'home__widgets__block__title'}>{title}</div>
  </div>
)

// function getColor(typeOperation, amount) {
// Math.sign(trans.literAmount) === 0
// ? '#00693E'
// : '#D22323',
// }

const CardDetail = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { id } = useParams()
  const [data, setData] = React.useState<any>(null)
  const [transactions, setTransactions] = React.useState<any>(null)
  const [openQrCode, setOpenQrCode] = React.useState({
    open: false,
    number: null,
  })
  const [openModalChangePinCode, setModalChangePinCode] = React.useState({
    status: false,
    uuid: null,
  })
  function getCardDetail() {
    firstCards({ number: id as any }).then(data => setData(data.data))
    transactionHistories({ cardNumber: id as any }).then(data =>
      setTransactions(data.data?.items)
    )
  }

  React.useEffect(() => {
    getCardDetail()
  }, [])
  if (data === null) {
    return <Preloader />
  }

  return (
    <div className={'container card-detail'}>
      <ModalQrCode
        open={openQrCode.open}
        handleClose={() => setOpenQrCode({ open: false, number: null })}
        number={openQrCode.number as any}
      />
      <ModalChangePinCode
        open={openModalChangePinCode.status}
        handleClose={() => setModalChangePinCode({ status: false, uuid: null })}
        uuid={openModalChangePinCode.uuid as any}
      />
      <GoBack />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TitleComponent title={'Детализация карты'} />{' '}
        <div
          style={{
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <CircleIcon style={{ fontSize: 10 }} />
            <CircleIcon style={{ fontSize: 10 }} />
            <CircleIcon style={{ fontSize: 10 }} />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              style={{
                boxShadow: '0px 1px 5px rgb(0 46 82 / 10%)',
                borderRadius: 4,
                color: 'var(--color-text)',
                fontWeight: '500',
                fontSize: 18,
                margin: '0 10px',
              }}
              onClick={() => {
                handleClose()
                setModalChangePinCode({ status: true, uuid: data.uuid })
              }}
            >
              Изменить пинкод
            </MenuItem>
            {data.status === 'active' && (
              <MenuItem
                style={{
                  boxShadow: '0px 1px 16px rgb(0 46 82 / 10%)',
                  borderRadius: 4,
                  color: 'red',
                  fontWeight: '500',
                  fontSize: 18,
                  margin: '0 10px',
                }}
                onClick={() => {
                  handleClose()
                  updateStatusCard({
                    number: data.uuid,
                    status: 'blocked_self',
                  }).then(() => getCardDetail())
                }}
              >
                Заблокировать карту
                <RemoveCircleIcon sx={{ marginLeft: '10px' }} />
              </MenuItem>
            )}
            {data.status === 'blocked_self' && (
              <MenuItem
                style={{
                  boxShadow: '0px 1px 16px rgb(0 46 82 / 10%)',
                  borderRadius: 4,
                  color: 'green',
                  fontWeight: '500',
                  fontSize: 18,
                  margin: '0 10px',
                }}
                onClick={() => {
                  handleClose()
                  updateStatusCard({
                    number: data.uuid,
                    status: 'active',
                  }).then(() => getCardDetail())
                }}
              >
                Разблокировать карту
                <GppGoodIcon sx={{ marginLeft: '10px' }} />
              </MenuItem>
            )}
          </Menu>
        </div>
      </div>

      <div
        className={'barcode'}
        onClick={() => setOpenQrCode({ open: true, number: data.number })}
      >
        <div
          style={{ padding: 5, fontWeight: '600', color: 'var(--color-text)' }}
        >
          Показать QR-code
        </div>
        <div style={{ marginLeft: 'auto', display: 'flex' }}>
          <img src={'/assets/icons/next.svg'} />
        </div>
      </div>
      <div>
        <Tabs title={'Номер карты'} value={data.number} />
        <Tabs
          title={'Дата активации'}
          value={format(new Date(data.activatedAt), 'dd.MM.yyyy в HH:mm')}
        />
        <Tabs title={'Статус'} value={getStatus(data.status) as any} />
      </div>
      <TitleComponent title={'Баланс'} style={{ fontSize: 22 }} />
      <div className={'card-detail__fuels'}>
        {data.balances ? (
          data?.balances?.map((balance: any) => (
            <Card
              key={balance?.id}
              value={`${getLiter(balance.amount, balance.literPrice)} л.`}
              title={balance?.fuel?.title}
            />
          ))
        ) : (
          <div>Список пуст</div>
        )}
      </div>
      <TitleComponent title={'Список транзакций'} style={{ fontSize: 22 }} />
      <div className={'transaction-table'}>
        <div className={'transaction-table__head'}>
          <div>Номер</div>
          <div>Тип</div>
          <div>Вид топлива</div>
          <div>Количество (л.)</div>
          <div>АЗС</div>
          <div>Дата</div>
        </div>
        {transactions ? (
          transactions?.map((trans: any) => {
            console.log('hello ', trans)
            return (
              <div key={trans.id} className={'transaction-table__body'}>
                <div>{trans?.card?.number || '-'}</div>
                <div>{Transactions(trans.operationType)}</div>
                <div>{trans?.fuel?.title || '-'}</div>
                <div>{getLiter(trans.amount, trans.literPrice)} л.</div>
                <div>{trans?.petrolStation?.title || '-'}</div>
                <div>
                  {format(new Date(trans.createdAt), 'dd.MM.yyyy в HH:mm') ||
                    '-'}
                </div>
              </div>
            )
          })
        ) : (
          <div>Список пуст</div>
        )}
      </div>
      <div className={'transactions-mobile'}>
        {transactions ? (
          transactions.map((trans: any) => {
            const countLiter = getLiter(trans.amount, trans.literPrice) as any
            console.log('hello --- ', countLiter, Math.sign(countLiter))
            return (
              <div key={trans.id} className={'transactions-mobile__block'}>
                <div className={'transactions-mobile__block__head'}>
                  <div>
                    {trans?.petrolStation?.title || '-'}
                    <p>
                      {trans?.petrolStation?.address ||
                        'Барнаул ул. Трактовая 64'}
                    </p>
                  </div>
                  <div>
                    {format(new Date(trans.createdAt), 'dd.MM.yyyy в HH:mm') ||
                      '-'}
                  </div>
                </div>
                <div
                  style={{
                    fontWeight: '600',
                    marginTop: 5,
                    color: Math.sign(countLiter) === -1 ? '#D22323' : '#00693E',
                  }}
                >
                  {countLiter && Math.sign(countLiter) === 1
                    ? '+' + countLiter
                    : countLiter}{' '}
                  л.
                </div>
                <div className={'transactions-mobile__block__body'}>
                  <div>{trans?.fuel?.title || '-'} /</div>
                  <div
                    style={{
                      color:
                        Math.sign(countLiter) === -1 ? '#D22323' : '#00693E',
                    }}
                  >
                    {Transactions(trans.operationType)}
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div>Список пуст</div>
        )}
      </div>
    </div>
  )
}
export default CardDetail
