import React, { useEffect } from 'react'
import { Button, FormControl } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { TextInputRa } from '../../components/inputs/text-field'
import './style.scss'

type Props = {
  defaultValues?: { number?: string; title?: string }
}

const Filter = (props: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  let params = new URLSearchParams(location.search)

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: props.defaultValues ?? {
      number: '',
      title: '',
    },
  })

  useEffect(() => {
    params.forEach((value, key) => {
      setValue(key as any, value)
    })
  }, [])

  const resetFilter = () => {
    reset({
      number: '',
      title: '',
    })
    navigate(`/`)
  }

  const onSubmit = (values: any) => {
    Object.entries(values).map(field => {
      params.delete(field[0])
      params.append(field[0], String(field[1]))
    })

    navigate(`/?${params.toString()}`)
  }

  return (
    <FormControl fullWidth className={''}>
      <div className={'list-card-filter'}>
        <div className={'list-card-filter__field'}>
          <TextInputRa
            name={'number'}
            label={'Номер карты'}
            control={control}
            errors={errors}
            placeholder={'Введите номер карты'}
          />
        </div>
        <div className={'list-card-filter__field'}>
          <TextInputRa
            name={'title'}
            label={'Наименование карты'}
            control={control}
            errors={errors}
            placeholder={'Введите наименование карты'}
          />
        </div>
      </div>
      <div className={'list-card-filter__block-button'}>
        <Button
          variant="contained"
          className={'btn-default btn-blue bnt-icon-ok'}
          onClick={handleSubmit(onSubmit)}
        >
          Применить
        </Button>
        <Button
          variant="contained"
          className={'btn-default btn-gray btn-icon-cancel'}
          onClick={resetFilter}
        >
          Очистить
        </Button>
      </div>
    </FormControl>
  )
}

export default Filter
